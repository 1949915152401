import { Company } from '@/interfaces/vuex/company';
import { PlanId } from '@/lib/enum';

export const initialCompanyState: Company = {
  name: '',
  uuid: '',
  phone: '',
  plan_id: PlanId.FREE.value,
  customer_id: '',
  uploaded_file_number: 0,
  subscription_id: '',
  next_paid_at: null,
  renewed_at: null,
  subscribed_at: null,
  active_features: [],
};
